<template>
  <div class="text-center w-100">
    <TTBtn
      fab
      depressed
      color="white"
      elevation="1"
      large
      data-test="aside-right-download-analytic"
      class="mb-2 v-btn--aside"
      :loading="loading"
      @click="downloadReport"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-arrow-to-bottom
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Выгрузить<br>
      отчет
    </div>
  </div>
</template>
<script>
import { API_REPORT_TASK_CONTENT } from '@/services/backend/snami/constants';
import { downloadCSVLink } from '@/utils/CSV';

export default {
  name: 'AppQuestionAnalyticsAsideRight',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async downloadReport() {
      try {
        this.loading = true;
        const link = API_REPORT_TASK_CONTENT(this.$route.params.id);
        await downloadCSVLink(link);
      } catch (e) {
        console.warn(e);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 3000);
        this.$root.$emit('showNotifier', {
          title: 'Подготовка файла',
          msg: 'Скачивание скоро начнется',
          timeout: 3000,
          type: 'info',
        });
      }
    },
  },
};
</script>

<style scoped>
/* stylelint-ignore no-empty-source */
</style>
